import React, { useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Skeleton,
  Alert,
  AlertTitle,
  Stack,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import StrippedTableRow from './StrippedTableRow';
import getDateFromIsoDate from '../utils/getDateFromIsoDate';
import useOrder from '../hooks/useOrder';
import SortableTableHeaderCell from './SortableTableHeaderCell';
import useToken from '../utils/useToken';
import request from '../utils/request';
import { status } from '../const/status';
import Merken from './Merken';
import { RowsPerPageContext } from '../App';
import CustomTablePagination from './CustomTablePagination';

function TableZurueckgewiesenCodes() {
  const { rowsPerPage, setRowsPerPage } = useContext(RowsPerPageContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(
    (searchParams && Number(searchParams.get('page'))) || 0
  );
  const { orderBy, orderDirection, handleTableHeaderClick } = useOrder(
    'zurueckgewiesenAt',
    'desc'
  );
  const getToken = useToken();
  const { isLoading, isFetching, error, data } = useQuery(
    ['icdCodingZurueckgewiesen', page, rowsPerPage, orderBy, orderDirection],
    async () => {
      const token = await getToken();
      const res = await request({
        url: `/api/icd-coding/status/${
          status.ZURUECKGEWIESEN
        }?limit=${rowsPerPage}&offset=${
          page * rowsPerPage
        }&orderBy=${orderBy}&orderDirection=${orderDirection}`,
        token,
      });
      return res.data;
    },
    { keepPreviousData: true }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSearchParams({ page: newPage });
  };

  return (
    <div>
      {error ? (
        <Alert severity="error">
          <AlertTitle>ICD Codierungen konnten nicht geladen werden.</AlertTitle>
          Grund: {error.message}
        </Alert>
      ) : (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <SortableTableHeaderCell
                    name="einsatzNummer"
                    label="D-Nummer"
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                    handleTableHeaderClick={handleTableHeaderClick}
                  />
                  <SortableTableHeaderCell
                    name="einsatzDatum"
                    label="Einsatzdatum"
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                    handleTableHeaderClick={handleTableHeaderClick}
                  />
                  <SortableTableHeaderCell
                    name="zurueckgewiesenAt"
                    label="Zurückgewiesen am"
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                    handleTableHeaderClick={handleTableHeaderClick}
                  />
                  <SortableTableHeaderCell
                    name="diagnoseIcdCode"
                    label="ICD Code"
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                    handleTableHeaderClick={handleTableHeaderClick}
                  />
                  <TableCell>Zurückweisungsgrund</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="rect" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rect" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rect" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rect" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rect" />
                    </TableCell>
                  </TableRow>
                ) : data && data.items && data.items.length ? (
                  data.items.map((row, index) => (
                    <StrippedTableRow
                      key={row.id}
                      hover
                      onClick={() =>
                        navigate(
                          `/icd-code/${row.id}?position=${index}&page=${page}`
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.einsatzNummer}
                      </TableCell>
                      <TableCell>
                        {getDateFromIsoDate(row.einsatzDatum)}
                      </TableCell>
                      <TableCell>
                        {row.zurueckgewiesenAt
                          ? getDateFromIsoDate(row.zurueckgewiesenAt)
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {row.diagnoseNichtCodierbar ? '-' : row.diagnoseIcdCode}
                      </TableCell>
                      <TableCell>{row.zurueckgewiesenKategorie}</TableCell>
                      <TableCell align="right">
                        <Merken id={row.id} marked={row.marked} />
                      </TableCell>
                    </StrippedTableRow>
                  ))
                ) : (
                  <Box mt={2} mb={2}>
                    Keine zurückgewiesenen ICD Codierungen gefunden.
                  </Box>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <CustomTablePagination
              data={data}
              page={page}
              handleChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          </Stack>
        </>
      )}
    </div>
  );
}

export default TableZurueckgewiesenCodes;
