import { useMsal } from '@azure/msal-react';
import {
  InteractionRequiredAuthError,
  BrowserAuthError,
} from '@azure/msal-browser';
import { apiRequest, loginRequest } from '../authConfig';

export default function useToken() {
  const { instance, inProgress } = useMsal();
  async function getToken() {
    const account = instance.getActiveAccount();
    console.log(inProgress, account);
    if (account) {
      try {
        const token = await instance.acquireTokenSilent({
          ...apiRequest,
          account,
        });
        return token.accessToken;
      } catch (error) {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        if (
          error instanceof InteractionRequiredAuthError ||
          error instanceof BrowserAuthError
        ) {
          instance.acquireTokenRedirect({
            ...apiRequest,
            account,
          });
        }
        throw new Error(error);
      }
    } else {
      instance.loginRedirect(loginRequest);
    }
  }

  return getToken;
}
