import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function Diagnose({ diagnose }) {
  const { diagnoseInWorten, diagnoseInWortenDetails, diagnoseIcdCode } =
    diagnose;
  return (
    <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 2 }}>
      <Grid item xs={12} md={4}>
        <Stack>
          <Typography
            sx={{
              color: '#3F3F3F',
              fontWeight: 700,
            }}
          >
            Nebendiagnose
          </Typography>
          <Typography
            sx={{
              color: 'black',
              fontWeight: 500,
            }}
          >
            {diagnoseInWorten || '-'}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack>
          <Typography
            sx={{
              color: '#3F3F3F',
              fontWeight: 700,
            }}
          >
            Details
          </Typography>
          <Typography>{diagnoseInWortenDetails || '-'}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack>
          <Typography
            sx={{
              color: '#3F3F3F',
              fontWeight: 700,
            }}
          >
            ICD Code
          </Typography>
          <Typography>{diagnoseIcdCode || '-'}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
