import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Alert,
  AlertTitle,
  Checkbox,
  IconButton,
  Grid,
  Button,
  CircularProgress,
  Tooltip,
  Stack,
} from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { utils, writeFileXLSX } from 'xlsx';
import StrippedTableRow from './StrippedTableRow';
import getDateFromIsoDate from '../utils/getDateFromIsoDate';
import getDateAndTimeFromIsoDate from '../utils/getDateAndTimeFromIsoDate';
import useToken from '../utils/useToken';
import request from '../utils/request';
import DownloadFileIcon from './DownloadFileIcon';

function TableFailedCodes() {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  const [retryError, setRetryError] = React.useState(null);
  const [deleteError, setDeleteError] = React.useState(null);
  const getToken = useToken();
  const { isLoading, error, data, refetch } = useQuery(
    ['icdCodingFailed'],
    async () => {
      const token = await getToken();
      const res = await request({
        url: `/api/icd-coding/failed-transfer`,
        token,
      });
      return res.data;
    }
  );

  const retryMutation = useMutation(async ({ id, data }) => {
    const token = await getToken();
    await request({
      url: `/api/icd-coding-retry-failed-transfer`,
      method: 'POST',
      data,
      token,
    });
  });

  const deleteMutation = useMutation(async ({ id, data }) => {
    const token = await getToken();
    await request({
      url: `/api/icd-coding-delete-failed-transfer`,
      method: 'POST',
      data,
      token,
    });
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.items.map((n) => n.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRetryClick = async () => {
    try {
      setRetryError(null);
      await retryMutation.mutateAsync({
        data: {
          ids: selected,
        },
      });
      setSelected([]);
      refetch();
    } catch (error) {
      setRetryError(error.message);
    }
  };

  const handleDeleteClick = async () => {
    try {
      setDeleteError(null);
      await deleteMutation.mutateAsync({
        data: {
          ids: selected,
        },
      });
      setSelected([]);
      refetch();
    } catch (error) {
      setDeleteError(error.message);
    }
  };

  const handleDownloadExcelClick = () => {
    const table = data.items.map((item) => ({
      'Patienten-ID': item.patientZuEinsatzId,
      'Ereignis-Nr.': item.ereignisNummer,
      'Dispatch-Nr.': item.einsatzNummer,
      'Einsatz-Datum': item.einsatzDatum,
      'Vorname/Nachname des Patienten': item.patientVornameNachname,
      'Arzt E-Mail Adressen': item.einsatzArztEmailAdressen,
      'Datum, wann Fall freigegeben wurde': item.freigegebenAt,
      'ICD-Code der Hauptdiagnose': item.diagnoseIcdCode,
      'Datum/Uhrzeit der fehlgeschlagenen Übermittlung an SAP':
        item.sendingToSapFailedAt && item.sendingToSap === 'Failed'
          ? item.sendingToSapFailedAt
          : '',
      'Fehlermeldung der fehlgeschlagenen Übermittlung an SAP':
        item.sendingToSapFailedAt && item.sendingToSap === 'Failed'
          ? JSON.stringify(item.sendingToSapFailedError)
          : '',
      'Datum/Uhrzeit der fehlgeschlagenen Übermittlung an SanostatEvo':
        item.sendingToSanostatFailedAt && item.sendingToSanostat === 'Failed'
          ? item.sendingToSanostatFailedAt
          : '',
      'Fehlermeldung der fehlgeschlagenen Übermittlung an SanostatEvo':
        item.sendingToSanostatFailedAt && item.sendingToSanostat === 'Failed'
          ? JSON.stringify(item.sendingToSanostatFailedError)
          : '',
    }));
    const ws = utils.json_to_sheet(table);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    const now = new Date().toISOString().split('T')[0];
    writeFileXLSX(wb, `${now}_Fehlgeschlagene_Datenübermittlungen.xlsx`);
  };

  return (
    <div>
      {error ? (
        <Alert severity="error">
          <AlertTitle>ICD Codierungen konnten nicht geladen werden.</AlertTitle>
          Grund: {error.message}
        </Alert>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Button
                  onClick={handleRetryClick}
                  disabled={selected.length === 0 || retryMutation.isLoading}
                >
                  Selektierte Einträge erneut übermitteln
                </Button>
                <Button
                  onClick={handleDeleteClick}
                  disabled={selected.length === 0 || retryMutation.isLoading}
                >
                  Selektierte Einträge aus Liste löschen{' '}
                </Button>
              </Stack>
              <IconButton
                onClick={handleDownloadExcelClick}
                aria-label="Download list as Excel"
                disabled={
                  data?.items.length === 0 ||
                  retryMutation.isLoading ||
                  isLoading
                }
              >
                <DownloadFileIcon />
              </IconButton>
            </Stack>
          </Grid>
          {retryError ? (
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>
                  Die erneute Übermittlung der ICD Codierungen konnte nicht
                  durchgeführt werden.
                </AlertTitle>
                Grund: {retryError}
              </Alert>
            </Grid>
          ) : null}
          {retryMutation.isLoading ? (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          ) : null}
          {retryMutation.isSuccess ? (
            <Grid item xs={12}>
              <Alert
                severity="success"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => refetch()}
                  >
                    Aktualisieren
                  </Button>
                }
              >
                Die ausgewählten ICD Codierungen wurden erneut übermittelt.
              </Alert>
            </Grid>
          ) : null}
          {deleteError ? (
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>
                  Die selektierten Einträge konnten nicht gelöscht werden.
                </AlertTitle>
                Grund: {retryError}
              </Alert>
            </Grid>
          ) : null}
          {deleteMutation.isLoading ? (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          ) : null}
          {deleteMutation.isSuccess ? (
            <Grid item xs={12}>
              <Alert
                severity="success"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => refetch()}
                  >
                    Aktualisieren
                  </Button>
                }
              >
                Die ausgewählten ICD Codierungen wurden gelöscht.
              </Alert>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={
                          !!(
                            data &&
                            data.items.length > 0 &&
                            selected.length > 0 &&
                            data.items.length === selected.length
                          )
                        }
                        onClick={handleSelectAllClick}
                      />
                    </TableCell>
                    <TableCell>D-Nummer</TableCell>
                    <TableCell>E-Nummer</TableCell>
                    <TableCell>Einsatzdatum</TableCell>
                    <TableCell>Freigegeben am</TableCell>
                    <TableCell>ICD Code</TableCell>
                    <TableCell>Fehlgeschlagen am (SanostatEvo)</TableCell>
                    <TableCell>Fehlgeschlagen am (SAP)</TableCell>
                    <TableCell>System</TableCell>
                    <TableCell>Detailansicht</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rect" />
                      </TableCell>
                    </TableRow>
                  ) : data && data.items && data.items.length ? (
                    data.items.map((row) => {
                      return (
                        <StrippedTableRow key={row.id} hover>
                          <TableCell>
                            <Checkbox
                              onClick={(e) => handleClick(e, row.id)}
                              checked={selected.some((s) => s === row.id)}
                            />
                          </TableCell>
                          <TableCell>{row.einsatzNummer}</TableCell>
                          <TableCell>{row.ereignisNummer}</TableCell>
                          <TableCell>
                            {getDateFromIsoDate(row.einsatzDatum)}
                          </TableCell>
                          <TableCell>
                            {row.freigegebenAt
                              ? getDateFromIsoDate(row.freigegebenAt)
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {row.diagnoseNichtCodierbar
                              ? '-'
                              : row.diagnoseIcdCode}
                          </TableCell>
                          <TableCell>
                            {row.sendingToSanostatFailedAt &&
                            row.sendingToSanostat === 'Failed' ? (
                              <Tooltip
                                leaveDelay={200}
                                title={
                                  row.sendingToSanostatFailedError ? (
                                    <>
                                      {JSON.stringify(
                                        row.sendingToSanostatFailedError,
                                        null,
                                        2
                                      )}
                                    </>
                                  ) : (
                                    'Keine Error Message gefunden'
                                  )
                                }
                              >
                                <span>
                                  {getDateAndTimeFromIsoDate(
                                    row.sendingToSanostatFailedAt
                                  )}
                                </span>
                              </Tooltip>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell>
                            {row.sendingToSapFailedAt &&
                            row.sendingToSap === 'Failed' ? (
                              <Tooltip
                                leaveDelay={200}
                                title={
                                  <>
                                    {JSON.stringify(
                                      row.sendingToSapFailedError,
                                      null,
                                      2
                                    )}
                                  </>
                                }
                              >
                                <span>
                                  {getDateAndTimeFromIsoDate(
                                    row.sendingToSapFailedAt
                                  )}
                                </span>
                              </Tooltip>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell>
                            {row.sendingToSanostat === 'Failed'
                              ? 'SanostatEvo '
                              : ''}
                            {row.sendingToSap === 'Failed' ? 'SAP ' : ''}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => navigate(`/icd-code/${row.id}`)}
                            >
                              <ArrowCircleRightOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        </StrippedTableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10}>
                        Keine fehlgeschlagenen Übertragungen gefunden.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default TableFailedCodes;
