import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import getDateFromIsoDate from '../utils/getDateFromIsoDate';
import SortableTableHeaderCell from './SortableTableHeaderCell';
import StrippedTableRow from './StrippedTableRow';

function TableSearchCodes({
  items,
  orderBy,
  orderDirection,
  handleTableHeaderClick,
}) {
  const navigate = useNavigate();

  if (items.length === 0) {
    return <div>Keine Resulte gefunden</div>;
  }

  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                name="einsatzNummer"
                label="D-Nummer"
                orderBy={orderBy}
                orderDirection={orderDirection}
                handleTableHeaderClick={handleTableHeaderClick}
              />
              <SortableTableHeaderCell
                name="ereignisNummer"
                label="E-Nummer"
                orderBy={orderBy}
                orderDirection={orderDirection}
                handleTableHeaderClick={handleTableHeaderClick}
              />
              <SortableTableHeaderCell
                name="status"
                label="Status"
                orderBy={orderBy}
                orderDirection={orderDirection}
                handleTableHeaderClick={handleTableHeaderClick}
              />
              <SortableTableHeaderCell
                name="einsatzDatum"
                label="Einsatzdatum"
                orderBy={orderBy}
                orderDirection={orderDirection}
                handleTableHeaderClick={handleTableHeaderClick}
              />
              <SortableTableHeaderCell
                name="freigegebenAt"
                label="Freigegeben am"
                orderBy={orderBy}
                orderDirection={orderDirection}
                handleTableHeaderClick={handleTableHeaderClick}
              />
              <SortableTableHeaderCell
                name="diagnoseIcdCode"
                label="ICD Code"
                orderBy={orderBy}
                orderDirection={orderDirection}
                handleTableHeaderClick={handleTableHeaderClick}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <StrippedTableRow
                key={row.id}
                hover
                onClick={() => navigate(`/icd-code/${row.id}`)}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <TableCell>{row.einsatzNummer}</TableCell>
                <TableCell>{row.ereignisNummer}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{getDateFromIsoDate(row.einsatzDatum)}</TableCell>
                <TableCell>
                  {row.freigegebenAt
                    ? getDateFromIsoDate(row.freigegebenAt)
                    : '-'}
                </TableCell>
                <TableCell>
                  {row.diagnoseNichtCodierbar ? '-' : row.diagnoseIcdCode}
                </TableCell>
              </StrippedTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TableSearchCodes;
