import * as React from 'react';
import { createContext, useState } from 'react';
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  styled,
  Button,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { SnackbarProvider } from 'notistack';
import { loginRequest } from './authConfig';
import Home from './pages/Home';
import Detail from './pages/Detail';
import Suche from './pages/Suche';
import FailedTransfer from './pages/FailedTransfer';
import Freigegeben from './pages/Freigegeben';
import Layout from './components/Layout';
import Zuruechgewiesen from './pages/Zuruechgewiesen';
import { pageSize } from './const/pageSize';

// Create a client
const queryClient = new QueryClient();

export const RowsPerPageContext = createContext(null);

function App({ pca }) {
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  return (
    <MsalProvider instance={pca}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          iconVariant={{
            default: <CheckCircleOutlineIcon sx={{ marginRight: 1 }} />,
          }}
        >
          <RowsPerPageContext.Provider value={{ rowsPerPage, setRowsPerPage }}>
            <RoutesWrapper />
          </RowsPerPageContext.Provider>
        </SnackbarProvider>
      </QueryClientProvider>
    </MsalProvider>
  );
}

function LoginButton() {
  const { instance } = useMsal();

  return (
    <Button onClick={() => instance.loginRedirect(loginRequest)}>Login</Button>
  );
}

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

function LoginPage() {
  return (
    <>
      <nav>
        <AppBar
          position="fixed"
          color="default"
          sx={{ backgroundColor: 'white' }}
        >
          <Toolbar variant="dense" sx={{ justifyContent: 'space-between' }}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography variant="subtitle1">ICD Codierung</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item sx={{ display: 'flex' }}>
                <img
                  src="/assets/img/logo.svg"
                  alt="Rega Logo"
                  width={30}
                  height={30}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Offset />
      </nav>
      <center>
        <LoginButton />
      </center>
    </>
  );
}

function RoutesWrapper() {
  return (
    <>
      <AuthenticatedTemplate>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/icd-code/:id" element={<Detail />} />
            <Route path="/suche" element={<Suche />} />
            <Route path="/failed-transfer" element={<FailedTransfer />} />
            <Route path="/freigegeben" element={<Freigegeben />} />
            <Route path="/zurueckgewiesen" element={<Zuruechgewiesen />} />
          </Routes>
        </Layout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
