import { TableCell, TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

function SortableTableHeaderCell({
  name,
  label,
  orderBy,
  orderDirection,
  handleTableHeaderClick,
}) {
  return (
    <TableCell sortDirection={orderBy === name ? orderDirection : false}>
      <TableSortLabel
        active={orderBy === name}
        direction={orderBy === name ? orderDirection : 'asc'}
        onClick={() => handleTableHeaderClick(name)}
      >
        {label}
        {orderBy === name ? (
          <Box component="span" sx={visuallyHidden}>
            {orderDirection === 'desc'
              ? 'sorted descending'
              : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
}

export default SortableTableHeaderCell;
