import SvgIcon from '@mui/material/SvgIcon';

export default function BookmarkIconActive(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V22.276C21.0001 22.3594 20.9793 22.4416 20.9395 22.5149C20.8997 22.5882 20.8422 22.6505 20.7722 22.6959C20.7023 22.7413 20.622 22.7685 20.5388 22.775C20.4557 22.7815 20.3722 22.767 20.296 22.733L12 19.03L3.704 22.732C3.6279 22.766 3.54451 22.7805 3.46141 22.774C3.37831 22.7676 3.29813 22.7405 3.22818 22.6952C3.15822 22.6499 3.1007 22.5878 3.06085 22.5146C3.021 22.4414 3.00008 22.3593 3 22.276V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2ZM12 13.5L14.939 15.045L14.378 11.773L16.755 9.455L13.469 8.977L12 6L10.53 8.977L7.245 9.455L9.622 11.773L9.062 15.045L12 13.5Z"
        fill="#0063AE"
      />
    </SvgIcon>
  );
}
