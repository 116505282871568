import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { status as statusConst } from '../const/status';

export default function ZurueckgewiesenBox({
  status,
  zurueckgewiesenKategorie,
  zurueckgewiesenBeschreibung,
}) {
  if (status === statusConst.ZURUECKGEWIESEN) {
    return (
      <Grid item xs={12}>
        <Box
          sx={{
            borderColor: '#F08825',
            borderStyle: 'solid',
            borderWidth: 1,
            backgroundColor: '#FFF5EB',
            padding: 2,
          }}
        >
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            Zurückweisungsgrund
          </Typography>
          <Typography variant="subtitle1" fontWeight="600">
            {zurueckgewiesenKategorie || 'Keine Kategorie vorhanden'}
          </Typography>
          <Typography variant="body1">
            {zurueckgewiesenBeschreibung || 'Keine Beschreibung vorhanden'}
          </Typography>
        </Box>
      </Grid>
    );
  }
  return null;
}
