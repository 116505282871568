import { useState, useEffect, useContext } from 'react';
import {
  Button,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Tooltip,
  Alert,
  AlertTitle,
  useMediaQuery,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Diagnose from './Diagnose';
import ZurueckweisenDialog from './ZurueckweisenDialog';
import getDateFromIsoDate from '../utils/getDateFromIsoDate';
import isDueDate from '../utils/isDueDate';
import useToken from '../utils/useToken';
import request from '../utils/request';
import { status } from '../const/status';
import Merken from './Merken';
import getNextCode from '../utils/getNextCode';
import ZurueckgewiesenBox from './ZurueckgewiesenBox';
import NoEmailIcon from './NoEmailIcon';
import { RowsPerPageContext } from '../App';

const MIN_WIDTH_HEADER = 150;

const CssTextField = styled(TextField)({
  '& .MuiInput-root.Mui-disabled': {
    backgroundColor: '#F3F3F3',
  },
  '& .MuiInput-root': {
    backgroundColor: 'white',
    padding: 4,
    paddingRight: 8,
    paddingLeft: 8,
  },
});

function getDiagnoseValue(
  diagnoseIcdCode,
  diagnoseNichtCodierbar,
  isFreigegeben
) {
  if (diagnoseNichtCodierbar) return '';
  if (isFreigegeben) return diagnoseIcdCode.slice(0, -1);
  return diagnoseIcdCode;
}

function getNoNextCodePath(isZurückgewiesen, isFreigegeben) {
  if (isZurückgewiesen) return '/zurueckgewiesen';
  if (isFreigegeben) return '/freigegeben';
  return '/';
}

function getFetchIcdCodeUrl(isZurückgewiesen, isFreigegeben, offset) {
  if (isZurückgewiesen) {
    return `/api/icd-coding/status/${status.ZURUECKGEWIESEN}?limit=5&offset=${offset}&orderBy=zurueckgewiesenAt&orderDirection=desc`;
  }
  if (isFreigegeben) {
    return `/api/icd-coding/status/${status.FREIGEGEBEN}?limit=5&offset=${offset}&orderBy=freigegebenAt&orderDirection=desc`;
  }
  return `/api/icd-coding/status/offen?limit=5&offset=${offset}`;
}

function IcdCodingForm({ item }) {
  const { rowsPerPage } = useContext(RowsPerPageContext);
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isZurückgewiesen = item.status === status.ZURUECKGEWIESEN;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [error, setError] = useState(false);
  const [diagnoseIcdCode, setDiagnoseIcdCode] = useState('');
  const [diagnoseNichtCodierbar, setDiagnoseNichtCodierbar] = useState(false);
  const getToken = useToken();
  const { enqueueSnackbar } = useSnackbar();

  const page = Number(searchParams.get('page'));
  const position = Number(searchParams.get('position'));

  const patchMutation = useMutation(async ({ id, data }) => {
    const token = await getToken();
    await request({
      url: `/api/icd-coding/${id}`,
      method: 'PATCH',
      data,
      token,
    });
  });
  const freigebenMutation = useMutation(
    async ({ id, data }) => {
      const token = await getToken();
      await request({
        url: `/api/icd-coding/${id}/freigeben`,
        method: 'PATCH',
        data,
        token,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['icdCoding-' + item.id] });
        queryClient.invalidateQueries({ queryKey: ['icdCodingOpen'] });
      },
      onError: () => {
        enqueueSnackbar(
          'Der Server ist aktuell nicht erreichbar. Versuchen Sie die Aktion zu einem späteren Zeitpunkt noch einmal.',
          { variant: 'error' }
        );
      },
    }
  );

  const editMutation = useMutation(
    async ({ id }) => {
      const token = await getToken();
      await request({
        url: `/api/icd-coding/${id}/in_bearbeitung`,
        method: 'PATCH',
        token,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['icdCoding-' + item.id] });
        queryClient.invalidateQueries({ queryKey: ['icdCodingOpen'] });
      },
    }
  );

  const { isLoading, data, refetch } = useQuery(
    [`icdCoding${isZurückgewiesen ? 'Zurueckgewiesen' : 'Open'}Page${page}`],
    async () => {
      const token = await getToken();
      const offset =
        searchParams.get('page') && searchParams.get('position')
          ? page * rowsPerPage + position + 1
          : 0;
      const res = await request({
        url: getFetchIcdCodeUrl(isZurückgewiesen, isFreigegeben, offset),
        token,
      });
      return res.data;
    }
  );

  useEffect(() => {
    if (item) {
      setDiagnoseIcdCode(item.diagnoseIcdCode || '');
      setDiagnoseNichtCodierbar(item.diagnoseNichtCodierbar || false);
    }
  }, [item]);

  const handleIcdCodeChange = (e) => {
    setDiagnoseIcdCode(e.target.value.trim().toUpperCase());
  };

  const handleIcdCodeBlur = () => {
    patchMutation.mutate({
      id: item.id,
      data: {
        diagnoseIcdCode,
        diagnoseNichtCodierbar,
      },
    });
  };

  const editIcdCode = () => {
    editMutation.mutate({
      id: item.id,
    });
  };

  const handleDiagnoseNichtCodierbarChange = (e) => {
    setDiagnoseNichtCodierbar(e.target.checked);
    patchMutation.mutate({
      id: item.id,
      data: {
        diagnoseIcdCode: '',
        diagnoseNichtCodierbar: e.target.checked,
      },
    });
  };

  const saveIcdCode = async (loadNext) => {
    if (diagnoseNichtCodierbar || diagnoseIcdCode.length === 3) {
      setError(false);
      try {
        await patchMutation.mutateAsync({
          id: item.id,
          data: {
            diagnoseIcdCode,
            diagnoseNichtCodierbar,
          },
        });
        await freigebenMutation.mutateAsync({
          id: item.id,
          data: {
            diagnoseIcdCode: diagnoseNichtCodierbar ? '' : diagnoseIcdCode,
            diagnoseNichtCodierbar,
          },
        });
        enqueueSnackbar(
          <div>
            <b>{item.einsatzNummer}</b> wurde freigegeben
          </div>,
          {
            variant: 'default',
          }
        );
        const nextCode = getNextCode(data, item.id);
        const noNextCodePath = isZurückgewiesen ? '/zurueckgewiesen' : '/';
        navigate(
          loadNext && nextCode
            ? `/icd-code/${nextCode.id}?page=${page || 0}&position=${
                position + 1
              }`
            : noNextCodePath
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(true);
    }
  };

  const nextIcdCode = async (patchBeforeNext = true) => {
    try {
      await refetch();
      if (patchBeforeNext) {
        await patchMutation.mutateAsync({
          id: item.id,
          data: {
            diagnoseIcdCode,
            diagnoseNichtCodierbar,
          },
        });
      }
      console.log(data);
      const nextCode = getNextCode(data, item.id);
      const noNextCodePath = getNoNextCodePath(isZurückgewiesen, isFreigegeben);
      console.log(nextCode);
      navigate(
        nextCode
          ? `/icd-code/${nextCode.id}?page=${page || 0}&position=${
              position + 1
            }`
          : noNextCodePath
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveIcdCode(true);
  };

  const isFreigegeben = item.status === status.FREIGEGEBEN;

  const showWarning = isDueDate(item.einsatzDatum);

  const hauptDiagnose =
    item &&
    item.diagnosen.find((diagnose) => diagnose.diagnoseIstHauptdiagnose);

  const weitereDiagnosen = item.diagnosen.filter(
    (d) => !d.diagnoseIstHauptdiagnose
  );
  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="text"
              startIcon={<ChevronLeftIcon />}
              onClick={() => navigate(-1)}
            >
              Zurück
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{ padding: 2, backgroundColor: '#F5FAFE' }}
              alignItems="center"
            >
              <Grid item xs={10}>
                <Grid container>
                  <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
                    <Stack direction="column">
                      <Box
                        sx={{
                          minWidth: MIN_WIDTH_HEADER,
                          color: '#3F3F3F',
                          fontWeight: 700,
                        }}
                      >
                        D-Nummer:{' '}
                      </Box>
                      <Box sx={{ fontSize: '24px' }}>{item.einsatzNummer}</Box>
                    </Stack>
                    <Stack direction="column">
                      <Box
                        sx={{
                          minWidth: MIN_WIDTH_HEADER,
                          color: '#3F3F3F',
                          fontWeight: 700,
                        }}
                      >
                        E-Nummer:{' '}
                      </Box>
                      <Box sx={{ fontSize: '24px' }}>{item.ereignisNummer}</Box>
                    </Stack>
                    <Stack direction="column">
                      <Box
                        sx={{
                          minWidth: MIN_WIDTH_HEADER,
                          color: '#3F3F3F',
                          fontWeight: 700,
                        }}
                      >
                        Einsatzdatum:{' '}
                      </Box>
                      <Box
                        sx={{
                          fontSize: '24px',
                          color:
                            showWarning && !isFreigegeben
                              ? 'error.main'
                              : 'default',
                        }}
                      >
                        {getDateFromIsoDate(item.einsatzDatum)}
                      </Box>
                    </Stack>
                    {item.status === 'Freigegeben' && (
                      <Stack direction="column">
                        <Box
                          sx={{
                            minWidth: MIN_WIDTH_HEADER,
                            color: '#3F3F3F',
                            fontWeight: 700,
                          }}
                        >
                          Freigegeben am:{' '}
                        </Box>
                        <Box>
                          <Tooltip title={`Freigegeben von: ${item.updatedBy}`}>
                            <Typography sx={{ fontSize: '24px' }}>
                              {getDateFromIsoDate(item.freigegebenAt)}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {!item.einsatzArztEmailAdressen && (
                    <Grid item>
                      <Tooltip title="Keine Arzt E-Mail-Adresse hinterlegt. Beim Zurückweisen des Falls wird keine E-Mail versendet.">
                        <span>
                          <IconButton
                            aria-label="Keine Arzt E-Mail-Adresse hinterlegt. Beim Zurückweisen des Falls wird keine E-Mail versendet."
                            disabled
                            color="primary"
                          >
                            <NoEmailIcon titleAccess="Keine Arzt E-Mail-Adresse hinterlegt. Beim Zurückweisen des Falls wird keine E-Mail versendet." />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid item>
                    <Merken id={item.id} marked={!!item.marked} isClickable />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ZurueckgewiesenBox
            status={item.status}
            zurueckgewiesenKategorie={item.zurueckgewiesenKategorie}
            zurueckgewiesenBeschreibung={item.zurueckgewiesenBeschreibung}
          />
          <Grid item xs={12}>
            <Box sx={{ padding: 2, backgroundColor: '#F5FAFE' }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={2}>
                        <Typography variant="h4">Hauptdiagnose</Typography>
                        {hauptDiagnose ? (
                          <Stack spacing={2}>
                            <Stack>
                              <Typography
                                sx={{ fontWeight: 700, fontSize: '20px' }}
                              >
                                {hauptDiagnose.diagnoseInWorten}
                              </Typography>
                            </Stack>
                            {hauptDiagnose.diagnoseInWortenDetails && (
                              <Stack>
                                <Typography
                                  sx={{
                                    color: '#3F3F3F',
                                    fontWeight: 500,
                                  }}
                                >
                                  Details
                                </Typography>
                                <Typography sx={{ fontWeight: 700 }}>
                                  {hauptDiagnose.diagnoseInWortenDetails}
                                </Typography>
                              </Stack>
                            )}
                            {hauptDiagnose.diagnoseIcdCode && (
                              <Stack>
                                <Typography
                                  sx={{
                                    color: '#3F3F3F',
                                    fontWeight: 500,
                                  }}
                                >
                                  ICD Code
                                </Typography>
                                <Typography sx={{ fontWeight: 700 }}>
                                  {hauptDiagnose.diagnoseIcdCode}
                                </Typography>
                              </Stack>
                            )}
                          </Stack>
                        ) : (
                          <Typography sx={{ fontWeight: 700 }}>
                            Keine Hauptdiagnose definiert
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ marginTop: isMobile && 4 }}>
                      <Stack spacing={2} direction="column">
                        <Typography variant="h4">Anamnese</Typography>
                        <Stack spacing={1}>
                          <Typography>{item.anamneseJetzigesLeiden}</Typography>
                          <Typography>{item.ohneDiagnoseGrund}</Typography>
                          <Typography>Naca: {item.naca}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Stack spacing={2}>
                      <Typography variant="h4">ICD Code</Typography>
                      <Box>
                        <Stack direction="row" spacing={2}>
                          <CssTextField
                            sx={{ width: 100 }}
                            autoFocus
                            variant="standard"
                            error={error}
                            helperText={
                              error && 'ICD Code muss 3 Zeichen haben'
                            }
                            value={getDiagnoseValue(
                              diagnoseIcdCode,
                              diagnoseNichtCodierbar,
                              isFreigegeben
                            )}
                            disabled={diagnoseNichtCodierbar || isFreigegeben}
                            onChange={handleIcdCodeChange}
                            onBlur={handleIcdCodeBlur}
                            inputProps={{ maxLength: 3 }}
                            InputProps={{
                              maxLength: 3,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography sx={{ fontWeight: 600 }}>
                                    V
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {isFreigegeben && (
                            <IconButton
                              aria-label="Kopiere ICD Code"
                              onClick={() => {
                                navigator.clipboard.writeText(diagnoseIcdCode);
                              }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Box>
                      <Box>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={diagnoseNichtCodierbar}
                                onChange={handleDiagnoseNichtCodierbarChange}
                              />
                            }
                            label="Keine Codierung möglich"
                            disabled={isFreigegeben}
                          />
                        </FormGroup>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Accordion disabled={weitereDiagnosen.length === 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: '#F3F3F3' }}
              >
                <Typography sx={{ fontWeight: 700 }}>
                  {weitereDiagnosen.length
                    ? 'Weitere Diagnosen'
                    : 'Keine weiteren Diagnosen'}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {weitereDiagnosen.map((diagnose, index) => (
                  <Box>
                    <Diagnose
                      key={diagnose.diagnoseZuPatientId}
                      diagnose={diagnose}
                    />
                    {index !== weitereDiagnosen.length - 1 && <Divider />}
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>

          {freigebenMutation.isError && (
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>{freigebenMutation.error.message}</AlertTitle>
                {freigebenMutation?.error?.response?.data &&
                  JSON.stringify(
                    freigebenMutation.error.response.data,
                    null,
                    2
                  )}
              </Alert>
            </Grid>
          )}
          {patchMutation.isError && (
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>{patchMutation.error.message}</AlertTitle>
                {patchMutation?.error?.response?.data &&
                  JSON.stringify(patchMutation.error.response.data, null, 2)}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" alignItems="center">
              {isFreigegeben ? (
                <Grid item>
                  <Stack
                    direction={isMobile ? 'column-reverse' : 'row'}
                    spacing={2}
                  >
                    <Button
                      color="neutral"
                      onClick={() => nextIcdCode(false)}
                      disabled={isLoading}
                    >
                      Nächster Fall
                    </Button>
                    <Button color="primary" onClick={() => editIcdCode()}>
                      Bearbeiten
                    </Button>
                  </Stack>
                </Grid>
              ) : (
                <Grid item>
                  <Stack
                    direction={isMobile ? 'column-reverse' : 'row'}
                    spacing={2}
                  >
                    <Button
                      color="neutral"
                      onClick={() => nextIcdCode()}
                      disabled={isLoading}
                    >
                      {isZurückgewiesen ? 'Nächster Fall' : 'Überspringen'}
                    </Button>
                    {!isZurückgewiesen && (
                      <ZurueckweisenDialog
                        item={item}
                        data={data}
                        page={page}
                        position={position}
                        diagnoseIcdCode={diagnoseIcdCode}
                        diagnoseNichtCodierbar={diagnoseNichtCodierbar}
                      />
                    )}
                    <Button disabled={isLoading} type="submit">
                      Freigeben
                    </Button>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

export default IcdCodingForm;
