import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import IcdCodingForm from '../components/IcdCodingForm';
import useToken from '../utils/useToken';
import request from '../utils/request';

const Detail = () => {
  const params = useParams();

  const { id } = params;
  const getToken = useToken();
  const { isLoading, error, data } = useQuery(
    ['icdCoding-' + id],
    async () => {
      const token = await getToken();
      const res = await request({
        url: `/api/icd-coding/${id}`,
        token,
      });
      return res.data;
    },
    {
      // enabled: router.isReady,
    }
  );

  if (error) {
    return 'Error: ICD Codierung ' + id + ' konnte nicht gefunden werden.';
  }

  if (isLoading) {
    return 'Loading...';
  }
  if (data) {
    return <IcdCodingForm item={data.item} />;
  }
  return null;
};

export default Detail;
