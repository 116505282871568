import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Navigation from './Navigation';

function Layout({ children }) {
  return (
    <div>
      <Navigation />
      <main>
        <Container maxWidth="lg">
          <Box sx={{ mt: 2, mb: 4 }}>{children}</Box>
        </Container>
      </main>
    </div>
  );
}

export default Layout;
