import React, { useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Skeleton,
  Alert,
  AlertTitle,
  Stack,
} from '@mui/material';
import StrippedTableRow from './StrippedTableRow';
import getDateFromIsoDate from '../utils/getDateFromIsoDate';
import isDueDate from '../utils/isDueDate';
import useToken from '../utils/useToken';
import request from '../utils/request';
import Merken from './Merken';
import { RowsPerPageContext } from '../App';
import CustomTablePagination from './CustomTablePagination';

function TableOpenCodes() {
  const { rowsPerPage, setRowsPerPage } = useContext(RowsPerPageContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(
    (searchParams && Number(searchParams.get('page'))) || 0
  );
  const getToken = useToken();
  const { isLoading, isFetching, error, data } = useQuery(
    ['icdCodingOpen', page, rowsPerPage],
    async () => {
      const token = await getToken();
      const res = await request({
        url: `/api/icd-coding/status/offen?limit=${rowsPerPage}&offset=${
          page * rowsPerPage
        }`,
        token,
      });
      return res.data;
    },
    {
      keepPreviousData: true,
      onError: (error) => {
        console.log('onError');
        console.log(error);
      },
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSearchParams({ page: newPage });
  };

  return (
    <div>
      {error ? (
        <Alert severity="error">
          <AlertTitle>ICD Codierungen konnten nicht geladen werden.</AlertTitle>
          Grund: {error.message}
        </Alert>
      ) : (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>D-Nummer</TableCell>
                  <TableCell>Einsatzdatum</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="rect" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rect" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rect" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rect" />
                    </TableCell>
                  </TableRow>
                ) : data && data.items && data.items.length ? (
                  data.items.map((row, index) => {
                    const showWarning = isDueDate(row.einsatzDatum);
                    return (
                      <StrippedTableRow
                        key={row.id}
                        hover
                        onClick={() =>
                          navigate(
                            `/icd-code/${row.id}?position=${index}&page=${page}`
                          )
                        }
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        <TableCell
                          sx={{ color: showWarning ? 'error.main' : 'default' }}
                        >
                          {row.einsatzNummer}
                        </TableCell>
                        <TableCell
                          sx={{ color: showWarning ? 'error.main' : 'default' }}
                        >
                          {getDateFromIsoDate(row.einsatzDatum)}
                        </TableCell>
                        <TableCell
                          sx={{ color: showWarning ? 'error.main' : 'default' }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell align="right">
                          <Merken id={row.id} marked={row.marked} />
                        </TableCell>
                      </StrippedTableRow>
                    );
                  })
                ) : (
                  <Box mt={2} mb={2}>
                    Keine offenen ICD Codierungen gefunden.
                  </Box>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <CustomTablePagination
              data={data}
              page={page}
              handleChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          </Stack>
        </>
      )}
    </div>
  );
}

export default TableOpenCodes;
