import SvgIcon from '@mui/material/SvgIcon';

export default function NoEmailIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20 7.238L12.072 14.338L4 7.216V19H11.07C11.1694 19.6933 11.373 20.3676 11.674 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V12.255C21.3892 11.8285 20.7139 11.5027 20 11.29V7.238ZM19.501 5H4.511L12.061 11.662L19.502 5H19.501ZM16.707 20.708C17.267 20.9759 17.8962 21.0635 18.5081 20.9588C19.1199 20.854 19.6842 20.5621 20.1231 20.1231C20.5621 19.6842 20.854 19.1199 20.9588 18.5081C21.0635 17.8962 20.9759 17.267 20.708 16.707L16.707 20.707V20.708ZM15.292 19.293L19.293 15.293C18.733 15.0251 18.1038 14.9375 17.4919 15.0422C16.8801 15.147 16.3158 15.4389 15.8769 15.8779C15.4379 16.3168 15.146 16.8811 15.0412 17.4929C14.9365 18.1048 15.0241 18.734 15.292 19.294V19.293ZM18 23C16.6739 23 15.4021 22.4732 14.4645 21.5355C13.5268 20.5979 13 19.3261 13 18C13 16.6739 13.5268 15.4021 14.4645 14.4645C15.4021 13.5268 16.6739 13 18 13C19.3261 13 20.5979 13.5268 21.5355 14.4645C22.4732 15.4021 23 16.6739 23 18C23 19.3261 22.4732 20.5979 21.5355 21.5355C20.5979 22.4732 19.3261 23 18 23Z"
        fill="black"
      />
    </SvgIcon>
  );
}
