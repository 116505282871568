import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';

const StrippedTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F5FAFE',
  },
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));

export default StrippedTableRow;
