import React from 'react';
import { Grid, Chip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Link from './Link';
import useToken from '../utils/useToken';
import request from '../utils/request';

function FailedTransferIcon() {
  const getToken = useToken();
  const { isSuccess, data } = useQuery(['icdCodingFailed'], async () => {
    const token = await getToken();
    const res = await request({
      url: '/api/icd-coding/failed-transfer',
      token,
    });
    return res.data;
  });
  if (isSuccess && data.items.length) {
    return (
      <Grid item>
        <Link
          href="/failed-transfer"
          sx={{ display: 'flex', textDecoration: 'none' }}
        >
          <Chip
            size="small"
            label={data.items.length}
            color="error"
            sx={{ fontWeight: 700 }}
            clickable
          />
        </Link>
      </Grid>
    );
  }
  return null;
}

export default FailedTransferIcon;
