import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';

export default function CustomTablePagination({
  data,
  page,
  handleChangePage,
  rowsPerPage,
  setRowsPerPage,
  isLoading,
  isFetching,
}) {
  const handleOnRowsPerPageChange = (e) => {
    handleChangePage(undefined, 0);
    setRowsPerPage(e.target.value);
  };
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      sx={{ marginTop: 1 }}
      component="div"
      count={data && data.total}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleOnRowsPerPageChange}
      disabled={isLoading || isFetching}
      showFirstButton
      showLastButton
      labelRowsPerPage="Zeilen pro Seite"
      labelDisplayedRows={({ from, to, count }) => {
        return `${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`;
      }}
    />
  );
}
