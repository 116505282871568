import axios from 'axios';

// optionaly add base url
const client = axios.create({
  baseURL: process.env.REACT_APP_FUNCTION_BASE_URL,
});

const request = ({ token, ...options }) => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;

  return client(options);
};

export default request;
