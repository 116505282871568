import React from 'react';
import {
  AppBar,
  styled,
  Toolbar,
  Grid,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import Link from './Link';
import ProfileMenu from './ProfilMenu';
import DrawerComponent from './DrawerComponent';
import FailedTransferIcon from './FailedTransferIcon';
import { useLocation } from 'react-router-dom';
import useToken from '../utils/useToken';
import { useQuery } from '@tanstack/react-query';
import request from '../utils/request';
import { status } from '../const/status';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#000',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    color: '#000',
    '&:hover': {
      opacity: 1,
      backgroundColor: '#F3F3F3',
    },
    '&.Mui-selected': {
      fontWeight: theme.typography.fontWeightMedium,
      color: '#000',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#F3F3F3',
    },
  })
);

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

function LinkTab(props) {
  const navigate = useNavigate();
  return (
    <StyledTab
      component="a"
      onClick={(event) => {
        event.preventDefault();
        navigate(props.href);
      }}
      {...props}
    />
  );
}

const validPathNames = ['/', '/freigegeben', '/zurueckgewiesen'];

function getId(pathname) {
  const isDetailPage = pathname.includes('/icd-code/');
  if (isDetailPage) {
    const [, id] = pathname.split('/icd-code/');
    return id;
  }
  return null;
}

function getTabValue(pathname, data) {
  if (validPathNames.some((p) => p === pathname)) {
    return pathname;
  }
  if (data && data.item) {
    const { status: itemStatus } = data.item;
    if (itemStatus === status.NEU || itemStatus === status.IN_BEARBEITUNG) {
      return '/';
    }
    if (itemStatus === status.FREIGEGEBEN) {
      return '/freigegeben';
    }
    if (itemStatus === status.ZURUECKGEWIESEN) {
      return '/zurueckgewiesen';
    }
  }
  return false;
}

function Navigation() {
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { pathname } = location;
  const id = getId(pathname);

  const getToken = useToken();
  const { data } = useQuery(
    ['icdCoding-' + id],
    async () => {
      const token = await getToken();
      const res = await request({
        url: `/api/icd-coding/${id}`,
        token,
      });
      return res.data;
    },
    {
      enabled: !!id,
    }
  );

  return (
    <nav>
      <AppBar
        position="fixed"
        color="default"
        sx={{ backgroundColor: 'white' }}
      >
        <Toolbar variant="dense" sx={{ justifyContent: 'space-between' }}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <DrawerComponent />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">ICD Codierung</Typography>
            </Grid>
          </Grid>
          {matches ? (
            <Grid>
              <StyledTabs value={getTabValue(pathname, data)}>
                <LinkTab value="/" label="Offen" href="/" />
                <LinkTab
                  value="/freigegeben"
                  label="Freigegeben"
                  href="/freigegeben"
                />
                <LinkTab
                  value="/zurueckgewiesen"
                  label="Zurückgewiesen"
                  href="/zurueckgewiesen"
                />
              </StyledTabs>
            </Grid>
          ) : null}
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Link href="/suche" sx={{ display: 'flex' }}>
                <SearchIcon sx={{ color: '#7F7F7F' }} />
              </Link>
            </Grid>
            <FailedTransferIcon />
            <ProfileMenu />
            <Grid item sx={{ display: 'flex', marginLeft: 1 }}>
              <img
                src="/assets/img/logo.svg"
                alt="Rega Logo"
                width={30}
                height={30}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Offset />
    </nav>
  );
}

export default Navigation;
