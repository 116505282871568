import { useState } from 'react';

function useOrder(defaultOrderBy, defaultOrderDirection) {
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [orderDirection, setOrderDirection] = useState(defaultOrderDirection);

  const handleTableHeaderClick = (name) => {
    const isAsc = orderBy === name && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(name);
  };

  return {
    orderBy,
    orderDirection,
    handleTableHeaderClick,
  };
}

export default useOrder;
