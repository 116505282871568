import { IconButton, CircularProgress, Box } from '@mui/material';
import BookmarkIconActive from './BookmarkIconActive';
import BookmarkIconInactive from './BookmarkIconInactive';
import useToken from '../utils/useToken';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import request from '../utils/request';

export default function Merken({ id, marked, isClickable = false }) {
  const queryClient = useQueryClient();

  const getToken = useToken();
  const markMutation = useMutation(async ({ id, data }) => {
    const token = await getToken();
    await request({
      url: `/api/icd-coding/${id}/merken`,
      method: 'PATCH',
      data,
      token,
    });
  });

  const handleClick = async () => {
    await markMutation.mutateAsync({
      id: id,
      data: {
        marked: !marked,
      },
    });
    queryClient.invalidateQueries({ queryKey: ['icdCoding-' + id] });
  };

  if (markMutation.isLoading) {
    return (
      <IconButton>
        <CircularProgress size={24} />
      </IconButton>
    );
  }

  if (marked) {
    if (isClickable) {
      return (
        <IconButton onClick={handleClick} disabled={markMutation.isLoading}>
          <BookmarkIconActive />
        </IconButton>
      );
    }
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <BookmarkIconActive />
      </Box>
    );
  }
  if (isClickable) {
    return (
      <IconButton onClick={handleClick} disabled={markMutation.isLoading}>
        <BookmarkIconInactive />
      </IconButton>
    );
  }
  return null;
}
