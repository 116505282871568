import * as React from 'react';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Alert, AlertTitle } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import request from '../utils/request';
import useToken from '../utils/useToken';
import getNextCode from '../utils/getNextCode';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export default function ZurueckweisenDialog({
  item,
  data,
  page,
  position,
  diagnoseIcdCode,
  diagnoseNichtCodierbar,
}) {
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState('');
  const [reason, setReason] = React.useState('');
  const getToken = useToken();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const patchMutation = useMutation(async ({ id, data }) => {
    const token = await getToken();
    await request({
      url: `/api/icd-coding/${id}`,
      method: 'PATCH',
      data,
      token,
    });
  });

  const zurückweisenMutation = useMutation(
    async ({ id, data }) => {
      const token = await getToken();
      await request({
        url: `/api/icd-coding/${id}/zurueckweisen`,
        method: 'PATCH',
        data,
        token,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['icdCoding-' + item.id] });
        queryClient.invalidateQueries({ queryKey: ['icdCodingOpen'] });
      },
      onError: () => {
        enqueueSnackbar(
          'Der Server ist aktuell nicht erreichbar. Versuchen Sie die Aktion zu einem späteren Zeitpunkt noch einmal.',
          { variant: 'error' }
        );
      },
    }
  );

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    zurückweisenIcdCode(true);
  };

  const zurückweisenIcdCode = async (loadNext) => {
    try {
      await patchMutation.mutateAsync({
        id: item.id,
        data: {
          diagnoseIcdCode,
          diagnoseNichtCodierbar,
        },
      });
      await zurückweisenMutation.mutateAsync({
        id: item.id,
        data: {
          diagnoseIcdCode: diagnoseNichtCodierbar ? '' : diagnoseIcdCode,
          diagnoseNichtCodierbar,
          zurueckgewiesenKategorie: category,
          zurueckgewiesenBeschreibung: reason,
        },
      });
      handleClose();
      enqueueSnackbar(
        <div>
          <b>{item.einsatzNummer}</b> wurde zurückgewiesen
        </div>,
        {
          variant: 'default',
        }
      );
      const nextCode = getNextCode(data, item.id);
      navigate(
        loadNext && nextCode
          ? `/icd-code/${nextCode.id}?page=${page || 1}&position=${
              position + 1
            }`
          : '/'
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Button color="neutral" onClick={handleClickOpen}>
        Zurückweisen
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Zurückweisen</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              Bitte geben Sie eine Begründung an.
            </DialogContentText>
            {!item.einsatzArztEmailAdressen && (
              <Alert severity="warning">
                <AlertTitle>Keine Arzt E-Mail-Adresse hinterlegt.</AlertTitle>
                Beim Zurückweisen des Falls wird keine E-Mail versendet.
              </Alert>
            )}
            <FormControl fullWidth variant="filled" required>
              <InputLabel id="demo-simple-select-label">Kategorie</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Kategorie"
                onChange={handleCategoryChange}
              >
                <MenuItem
                  value={
                    'Hauptdiagnose/Notfallgeschehen unzureichend für Codierung'
                  }
                >
                  Hauptdiagnose/Notfallgeschehen unzureichend für Codierung
                </MenuItem>
                <MenuItem
                  value={'Hauptdiagnose/Notfallgeschehen widersprüchlich'}
                >
                  Hauptdiagnose/Notfallgeschehen widersprüchlich
                </MenuItem>
                <MenuItem
                  value={
                    'Hauptdiagnose/Notfallgeschehen nicht kongruent betreffend Transportgrund'
                  }
                >
                  Hauptdiagnose/Notfallgeschehen nicht kongruent betreffend
                  Transportgrund
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="filled-multiline-static"
              label="Begründung"
              multiline
              rows={4}
              defaultValue="Default Value"
              variant="filled"
              value={reason}
              onChange={handleReasonChange}
              required
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="neutral" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button onClick={handleSubmit} disabled={!category || !reason}>
            Zurückweisen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
