import SvgIcon from '@mui/material/SvgIcon';

export default function CustomMenuIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4 11H17V13H4V11ZM4 6H20V8H4V6ZM4 18H11H14V16H9.5H4V18Z"
        fill="black"
      />
    </SvgIcon>
  );
}
