import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#0063AE',
    },
    secondary: {
      light: '#85BFE9',
      main: '#378BCA',
      dark: '#0063AE',
      contrastText: '#FFF',
    },
    neutral: {
      main: '#eeeeee',
      contrastText: '#212121',
    },
    error: {
      main: '#BA0000',
    },
  },
  typography: {
    fontFamily: 'Univers, Arial',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Univers';
          font-style: normal;
          font-display: swap;
          font-weight: normal;
          src: url("/fonts/1475958/19254118-a7ad-4609-8cee-8ef44a85bba1.eot?#iefix") format("eot"),url("/fonts/1475958/c5f91eda-7bd6-4dda-920e-a8aca7f11884.woff2") format("woff2"),url("/fonts/1475958/34799647-aca8-402d-a341-220207b91cbf.woff") format("woff"),url("/fonts/1475958/8186c8c1-cfdb-4c3e-b38e-6c2fd28486b5.ttf") format("truetype");
        }
        @font-face {
          font-family: 'Univers';
          font-style: normal;
          font-display: swap;
          font-weight: light;
          src: url("/fonts/1475946/81e80e98-03c4-4de6-aa5a-b3a8688d3a3f.eot?#iefix") format("eot"), url("/fonts/1475946/4201a9f6-3b6a-4b16-8b4d-39f514509601.woff2") format("woff2"), url("/fonts/1475946/9a52958b-cd5b-48d8-9427-c6a3b7fad417.woff") format("woff"),url("/fonts/1475946/5b8d7867-e8ee-4484-95aa-6e4bff75f379.ttf") format("truetype");
        }
        @font-face {
          font-family: 'Univers';
          font-style: normal;
          font-display: swap;
          font-weight: bold;
          src: url("/fonts/1475970/e4c74689-7dab-4e97-a1bd-4671a16bbe0a.eot?#iefix") format("eot"),url("/fonts/1475970/864f8bdc-1a42-4d28-97a5-c15513fd193b.woff2") format("woff2"),url("/fonts/1475970/5ae7499f-7af1-46c7-b908-2978d7379f56.woff") format("woff"),url("/fonts/1475970/bd4ea697-ae5b-421a-9a3c-d94f6f0ab7c0.ttf") format("truetype");
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          // transform: 'skew(-10deg)',
          borderRadius: 0,
          // '&:hover': {
          //   transform: 'skew(-10deg) translateY(-.1875rem)',
          // },
          label: {
            // transform: 'skew(10deg)',
            fontWeight: 600,
          },
          fontWeight: 600,
        },
      },
      defaultProps: {
        disableRipple: true,
        variant: 'contained',
        color: 'primary',
      },
    },
    MuiBackdrop: {
      defaultProps: {
        color: '#f3f3f3',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
      defaultProps: {
        elevation: 2,
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          border: 0,
        },
        head: {
          fontWeight: 500,
          color: '#767676',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid ' + grey[300],
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
        },
        title: {
          fontWeight: 600,
          fontSize: '1.2rem',
        },
        subheader: {
          fontSize: '0.9rem',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 16,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 2,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        InputLabelProps: {
          shrink: true,
        },
        color: 'secondary',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'filled',
        color: 'secondary',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 2,
        square: true,
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          color: 'black',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#F3F3F3',
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
