import {
  Container,
  Button,
  Stack,
  TextField,
  Skeleton,
  Alert,
  AlertTitle,
  Box,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import TableSearchCodes from '../components/TableSearchCodes';
import useOrder from '../hooks/useOrder';
import useToken from '../utils/useToken';
import request from '../utils/request';

const Suche = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { pathname } = location;
  const search = searchParams.get('search');
  const [suche, setSuche] = useState(search ? search : '');
  const { orderBy, orderDirection, handleTableHeaderClick } = useOrder(
    'einsatzNummer',
    'asc'
  );
  const getToken = useToken();
  const { refetch, isLoading, error, data, fetchStatus } = useQuery(
    ['icdCodingSuche', suche, orderBy, orderDirection],
    async () => {
      const token = await getToken();
      const res = await request({
        url: `/api/icd-coding/search/${suche}?orderBy=${orderBy}&orderDirection=${orderDirection}`,
        token,
      });
      return res.data;
    },
    {
      enabled: false,
    }
  );

  const isFetching = fetchStatus === 'fetching';

  useEffect(() => {
    if (suche.length) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, orderBy, orderDirection]);

  const handleSuche = (e) => {
    e.preventDefault();
    if (suche.length) {
      navigate(`${pathname}?search=${suche}`, undefined, { shallow: true });
      refetch();
    }
  };

  return (
    <form onSubmit={handleSuche}>
      <Container maxWidth="md">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <h1>Suchen</h1>
          <TextField
            fullWidth
            label="E / D-Nummer"
            autoFocus
            value={suche}
            onChange={(e) => setSuche(e.target.value)}
          />
          <Button type="submit" sx={{ minWidth: 300 }}>
            Suche
          </Button>
          {error && (
            <Alert severity="error">
              <AlertTitle>
                Bei der Suche nach ICD Codierungen ist ein Fehler aufgetreten.
              </AlertTitle>
              {error.message}
            </Alert>
          )}
          {isLoading ? null : isFetching ? (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ alignSelf: 'stretch' }}
            >
              <Box sx={{ alignSelf: 'stretch' }}>
                <Skeleton variant="rectangular" />
              </Box>
              <Box sx={{ alignSelf: 'stretch' }}>
                <Skeleton variant="rectangular" />
              </Box>
              <Box sx={{ alignSelf: 'stretch' }}>
                <Skeleton variant="rectangular" />
              </Box>
            </Stack>
          ) : (
            <Box>
              <TableSearchCodes
                items={data ? data.items : []}
                orderBy={orderBy}
                orderDirection={orderDirection}
                handleTableHeaderClick={handleTableHeaderClick}
              />
            </Box>
          )}
        </Stack>
      </Container>
    </form>
  );
};

export default Suche;
